import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/72cefc1d/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">Generalized shoulder procedure information</PageDescription>
    <h2 {...{
      "id": "instabilitydislocations"
    }}>{`Instability/Dislocations`}</h2>
    <p>{`Arthroscopic repair, Coracoid Transfer/Latarjet Reconstruction`}</p>
    <ul>
      <li parentName="ul">{`Labral tears`}</li>
      <li parentName="ul">{`SLAP tears - superior labrum anterior to posterior`}</li>
      <li parentName="ul">{`Chronic dislocations`}</li>
      <li parentName="ul">{`Coracoid transfer/Latarjet procedure`}</li>
    </ul>
    <h2 {...{
      "id": "rotator-cuff-injuries"
    }}>{`Rotator Cuff Injuries`}</h2>
    <p>{`Arthroscopic debridement and repair, reverse total shoulder arthroplasty`}</p>
    <ul>
      <li parentName="ul">{`Partial Thickness tears`}</li>
      <li parentName="ul">{`Complete tears`}</li>
    </ul>
    <h2 {...{
      "id": "other"
    }}>{`Other`}</h2>
    <p>{`Total shoulder arthroplasty, reverse total shoulder arthroplasty, arthroscopic and open biceps tenodesis.`}</p>
    <ul>
      <li parentName="ul">{`Biceps tendonitis and injuries`}</li>
      <li parentName="ul">{`Acromioclavicular joint sprains and dislocations`}</li>
      <li parentName="ul">{`Clavicle fractures`}</li>
      <li parentName="ul">{`Osteoarthritis`}</li>
      <li parentName="ul">{`Failed prior shoulder surgery`}</li>
    </ul>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      